import React from 'react';
import Clock from '@images/icon/Clock.svg';
import Cart from '@images/icon/Cart.svg';
import Money from '@images/icon/Money.svg';
import '../HomePage.scss';

const Tracking = () => {
  return (
    <div className="tracking">
      <div className="introduction">
        <div className="introduction-box-info">
          <img src={Clock} alt="clock" />
          <span>THỜI GIAN GIAO HÀNG NHANH</span>
        </div>
        <div className="introduction-box-info">
          <img src={Cart} alt="clock" />
          <span>VẬN CHUYỂN ĐA DẠNG CÁC MẶT HÀNG</span>
        </div>
        <div className="introduction-box-info">
          <img src={Money} alt="clock" />
          <span>GIÁ CƯỚC CẠNH TRANH NHẤT THỊ TRƯỜNG</span>
        </div>
      </div>
    </div>
  )
}

export default Tracking;