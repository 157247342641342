import BackgroudPage01 from '@images/background/BackgroudPage01.png';
import React from 'react';
import '../ServiceDetailPage.scss';
import ContactMap from './ContactMap';

const AirFreightService = () => {
  return (
    <>
      <div className='air-freight-service'>
        <p className='service-detail-content-title-text'>
          Những ưu điểm về dịch vụ vận tải đường hàng không tại SOL Express:
        </p>
        <p className='service-detail-text'>
          Dịch vụ vận chuyển của chúng tôi mang đến sự tiện lợi và an tâm cho khách hàng với nhiều ưu điểm vượt trội.
        </p>
        <div className='service-detail-content-flex-rows'>
          <div className='service-detail-content-flex-columns'>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p>Không giới hạn về kích thước và trọng lượng hàng hóa, đáp ứng mọi nhu cầu vận chuyển mà khách hàng yêu cầu.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p>Gửi hàng hóa và tài liệu đến hơn 200 quốc gia và vùng lãnh thổ.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p>Chuyên nghiệp trong việc xử lý và vận chuyển các mặt hàng khó gửi đi quốc tế như thuốc men, thực phẩm khô, đồ điện tử và nhiều loại hàng hóa khác.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p>Hỗ trợ khách hàng làm thủ tục mở tờ khai, cung cấp dịch vụ hải quan hai đầu xuất nhập và khai giá hàng hóa, giúp quy trình vận chuyển trở nên thuận lợi và nhanh chóng.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p>Có bảo hiểm hàng hóa trọn gói, bảo vệ giá trị hàng hóa trong suốt quá trình vận chuyển.</p>
            </div>
          </div>
          <img src={BackgroudPage01} className='service-detail-content-images' alt='air-freight-advantage-image' />
        </div>
        <p className='service-detail-text'>
          Với những ưu điểm trên, dịch vụ vận chuyển của chúng tôi luôn là <b>lựa chọn hàng đầu</b> cho mọi nhu cầu vận chuyển quốc tế của bạn.
        </p>
        <p className='service-detail-content-title-text'>
          Lợi ích khi lựa chọn dịch vụ vận tải đường hàng không tại SOL Express:
        </p>
        <p className='service-detail-text'>
          <b>Tiết kiệm chi phí vận chuyển với bảng giá cước tối ưu và ưu đãi:</b> Chúng tôi không chỉ đảm bảo nhu cầu vận chuyển nhanh chóng mà còn giúp bạn tiết kiệm đáng kể chi phí. SOL Express là đối tác trực tiếp của các hãng vận chuyển quốc tế uy tín như FedEx, DHL,... và nhiều hãng khác. Vì vậy, khi sử dụng dịch vụ của chúng tôi, bạn sẽ được hưởng mức cước phí ưu đãi hơn so với việc gửi hàng trực tiếp qua các hãng vận chuyển này. Điều này giúp bạn tiết kiệm chi phí mà vẫn đảm bảo hàng hóa được vận chuyển an toàn và đúng thời gian.
        </p>
        <p className='service-detail-text'>
          <b>Vận chuyển đa dạng hàng hóa:</b> Chúng tôi nhận gửi và vận chuyển mọi loại hàng hóa từ tài liệu đến thực phẩm tươi khô, hàng gia dụng, hàng điện tử và nhiều loại khác, kể cả các mặt hàng khó như Mỹ phẩm, Thuốc tây,... với tỷ lệ thành công cao. Quy trình thủ tục và các giấy tờ gửi hàng của chúng tôi đơn giản, nhanh chóng, không rườm rà và phức tạp.
        </p>
        <div className='service-detail-content-and-contact'>
          <div className='service-detail-content-view'>
            <p className='service-detail-text'>
              <b>Dịch vụ tận tâm, uy tín:</b> Chúng tôi nhận hàng từ khắp 63 tỉnh thành, tại mọi miền đất nước Việt Nam. Chúng tôi cung cấp dịch vụ đóng gói hàng miễn phí và đảm bảo đạt chuẩn quốc tế, giúp khách hàng yên tâm về sự an toàn và chất lượng của hàng hóa khi vận chuyển.        </p>
            <p className='service-detail-text'>
              <b>Tra cứu nhanh:</b> Chúng tôi cung cấp mã tracking ngay sau khi quý khách gửi hàng, giúp quý khách có thể theo dõi lịch trình đơn hàng mọi lúc mọi nơi. Đội ngũ SOL Express luôn sẵn sàng và tức trực để cập nhật thông tin và chủ động xử lý các vấn đề phát sinh trong quá trình vận chuyển một cách nhanh chóng và kịp thời.
            </p>
            <p className='service-detail-text'>
              <b>Chuyển phát nhanh:</b> Với dịch vụ door-to-door, chúng tôi sẽ chuyển phát hàng tại địa chỉ người nhận, giúp người nhận không phải di chuyển đến bất kỳ bưu cục nào để nhận hàng, đặc biệt là đối với các hàng hóa nặng và cồng kềnh.
            </p>
          </div>
          <ContactMap />
        </div>
        <p className='service-detail-content-title-text'>
          Bảng giá dịch vụ vận tải đường hàng không tại SOL Express
        </p>
        <p className='service-detail-text'>
          Với quý khách hàng có nhu cầu gửi hàng thường xuyên, vui lòng liên hệ với SOL qua <b>hotline: 0934 379 488</b> hoặc <b>fanpage chính thức</b> của SOL Express. Quý Khách hàng có thể liên hệ trực tiếp với chúng tôi để lựa chọn hình thức gửi bảng báo giá chi phí vận chuyển hàng hóa đi các nước trên thế giới.
        </p>
      </div>
    </>
  )
}

export default AirFreightService;