import React from 'react';
import '../ServiceDetailPage.scss';
import { Link } from 'react-router-dom';

const ServiceIntro = (props) => {
  const keyIndex = props.keyIndex;
  return (
    <div className="service-intro-view">
      {
        keyIndex === 1 ? (
          <>
            <p className='service-detail-text'>
              Hiện nay, <b>dịch vụ vận tải đường bộ - đường sắt</b> đã trở nên phổ biến nhưng không phải nơi nào cũng mang đến sự hài lòng. Tuy nhiên, khi đến với SOL Express, bạn sẽ có những trải nghiệm tuyệt vời với dịch vụ chuyên nghiệp và tận tâm nhất. Chúng tôi cam kết đem đến chất lượng vận chuyển vượt trội, đảm bảo hàng hóa của bạn được giao đúng thời gian và an toàn.
            </p>
            <p className='service-detail-content-title-text'>
              Vận tải đường bộ tại SOL Express như thế nào?
            </p>
            <p className='service-detail-text'>
              <b>Dịch vụ vận tải đường bộ của SOL Express cung cấp dịch vụ <b>vận chuyển hàng hóa bằng xe tải</b> qua các nước lân cận như <b>Thái Lan, Campuchia, Trung Quốc, Myanmar và Lào.</b> Chúng tôi cũng mang đến các giải pháp vận tải đa phương thức, kết hợp đường bộ với đường biển, đường hàng không, hoặc cả ba phương thức, nhằm đáp ứng tối ưu các nhu cầu vận chuyển của khách hàng.</b>
            </p>
            <p className='service-detail-text'>
              Đặc biệt, SOL Express cung cấp dịch vụ vận tải đường bộ giao nhận tận nơi (door to door). Với lịch trình các chuyến xe tải trung chuyển hàng ngày, chúng tôi đảm bảo đơn hàng được giao nhận linh hoạt và đúng tiến độ yêu cầu của khách hàng.
            </p>
            <p className='service-detail-text'>
              SOL Express còn trang bị đội xe đông lạnh để vận chuyển hàng hóa đặc biệt cần giữ lạnh trong quá trình vận chuyển như rau quả tươi, thủy hải sản, và hoa tươi cắt cành, đảm bảo chất lượng hàng hóa luôn được duy trì tốt nhất.
            </p>
            <p className='service-detail-text'>
              SOL Express nhận vận chuyển hàng hóa từ 0.5kg đến không giới hạn kích thước và trọng lượng từ TP.HCM đi các tỉnh thành ở Việt Nam. Chúng tôi hỗ trợ dịch vụ vận tải nội địa với các loại xe từ 3.5 tấn, 10 tấn đến vận chuyển nguyên container, đảm bảo đáp ứng mọi nhu cầu vận chuyển của khách hàng.
            </p>
          </>
        ) : keyIndex === 2 ? (
          <>
            <p className='service-detail-text'>
              Với <b>dịch vụ vận chuyển đường hàng không, SOL Express</b> giúp quý khách hàng kết nối và gửi đa dạng các mặt hàng hoá đến <b>hơn 200 quốc gia và vùng lãnh thổ trên thế giới.</b> Chúng tôi cam kết thời gian giao hàng nhanh chóng cùng giá cước tối ưu nhất thị trường.
            </p>
            <p className='service-detail-content-title-text'>
              Vận tải đường hàng không tại SOL Express như thế nào?
            </p>
            <p className='service-detail-text'>
              <b>Dịch vụ vận chuyển đường hàng không</b> của <b>SOL Express</b> cung cấp giải pháp giao - nhận và vận tải toàn diện cho mọi loại hàng hoá xuất/nhập khẩu. Chúng tôi đảm bảo <b>tính linh hoạt</b> với <b>thời gian giao hàng đúng hẹn</b> và <b>mức chi phí cạnh tranh.</b>
            </p>
            <p className='service-detail-text'>
              Với đội ngũ nhân viên dày dặn kinh nghiệm và chuyên môn cao trong việc xử lý các tình huống vận tải đường hàng không, SOL Express cam kết mang đến dịch vụ nhanh chóng và chuyên nghiệp nhất cho quý khách hàng.
            </p>
          </>
        ) : keyIndex === 3 ? (
          <>
            <p className='service-detail-text'>
              Với đội ngũ nhân viên chuyên nghiệp và nhiều năm kinh nghiệm, SOL Express tự hào là đại lý vận tải đường biển có nhiều mối quan hệ quan trọng trong lĩnh vực vận chuyển quốc tế.
            </p>
            <p className='service-detail-text'>
              Dịch vụ vận tải đường biển của SOL EXPRESS mang lại sự yên tâm về cước phí và chất lượng cho từng lô hàng. Đây sẽ là lựa chọn phù hợp cho hàng hóa có kích thước cồng kềnh, trọng lượng nặng hoặc số lượng lớn với chi phí tiết kiệm và nhanh chóng nhất.
            </p>
            <p className='service-detail-content-title-text'>
              Vận tải đường biển tại SOL Express như thế nào?
            </p>
            <p className='service-detail-text'>
              <b>Vận tải đường biển</b> là các hoạt động vận tải liên quan tới cơ sở hạ tầng và phương tiện đường biển. Cụ thể hơn, vận tải đường biển là việc sử dụng những khu nước, khu đất nối liền với các tuyến đường biển giữa các quốc gia hoặc vùng lãnh thổ trong phạm vi khu vực của một quốc gia.
            </p>
            <p className='service-detail-text'>
              Vận chuyển hàng hóa đường biển sử dụng các <b>phương tiện chuyên chở</b> như tàu, thuyền và phương tiện bốc dỡ hàng hóa, kết hợp với cơ sở hạ tầng như cảng biển hoặc cảng trung chuyển hàng hóa.
            </p>
            <p className='service-detail-text'>
              SOL Express chuyên cung cấp dịch vụ vận tải đường biển giá rẻ và uy tín, bên cạnh các loại hình vận chuyển khác như đường hàng không và đường bộ. Chúng tôi cam kết mang đến sự hài lòng cho khách hàng với dịch vụ chất lượng và đáng tin cậy.
            </p>
            <p className='service-detail-text'>
              SOL EXPRESS thực hiện dịch vụ vận tải hàng hóa đến khắp mọi nơi trên thế giới bằng đường biển và đường hàng không. Chúng tôi đặc biệt mạnh về dịch vụ vận tải đường biển nội địa và xuất nhập khẩu đến các nước như Malaysia, Mỹ, Canada, Úc và hơn 200 quốc gia khác. Với mức giá cạnh tranh, chúng tôi cung cấp dịch vụ vận tải chất lượng mà ít công ty xuất nhập khẩu nào có thể đáp ứng được.
            </p>
          </>
        ) : (
          <>
          </>
        )
      }
    </div>
  )
}

export default ServiceIntro;