import React from 'react';
import { Link } from 'react-router-dom';
import '../ServiceDetailPage.scss';

const ContactInfo = () => {
  return (
    <div className="contact-info-view">
      <span className='contact-info-title'>
        Thông tin liên hệ
      </span>
      <span className='contact-info-content'>
        Địa chỉ: 159/30 Hoàng Văn Thụ, phường 8, quận Phú Nhuận, Thành phố Hồ Chí Minh
      </span>
      <span className='contact-info-content'>
        Phone: (+84) 934 379 488
      </span>
      <span className='contact-info-content'>
        Fanpage: 
        <Link to='https://www.facebook.com/solexpressvn/?ref=embed_page'>
          Fb/solexpressvn/
        </Link>
      </span>
      <span className='contact-info-content'>
        Webdite: 
        <Link to='https://www.solexpress.vn'>
          solexpress.vn
        </Link>
      </span>
    </div>
  )
}

export default ContactInfo;