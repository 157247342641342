import React from 'react';
import './Header.scss';
import Logo from '@images/Logo.png';
import { Link } from 'react-router-dom';
import { Dropdown } from 'antd';

const Header = props => {

  const items = [
    {
      key: 1,
      label: (
        <Link to="/dich-vu/van-tai-duong-bo">
          Vận tải đường bộ
        </Link>
      ),
    },
    {
      key: 2,
      label: (
        <Link to="/dich-vu/van-tai-duong-hang-khong">
          Vận tải đường hàng không
        </Link>
      ),
    },
    {
      key: 3,
      label: (
        <Link to="/dich-vu/van-tai-duong-bien">
          Vận tải đường biển
        </Link>
      ),
    },
    {
      key: 4,
      label: (
        <Link to="/dich-vu/dich-vu-chuyen-phat">
          Dịch vụ chuyển phát
        </Link>
      ),
    },
  ];

  
  return (
    <header>
      <div className='header'>
        <div className='header-container'>
        <Link to='/' className="header-logo-link">
          <img src={Logo} alt='logo' className='header-logo' />
          </Link>
          <div className='header-detail'>
            <div className='header-company-info-view'>
              <Link to='https://maps.app.goo.gl/ChxFAzMB5ureaQh29' target="_blank" className='header-company-text' >
                <ion-icon name="location" />
                159/30 Hoàng Văn Thụ, P.8, Q.Phú Nhuận, TP.HCM
              </Link>
              <Link to='mailto:info.solexpress@gmail.com' target="_blank" className='header-company-text' >
                <ion-icon name="mail" />
                info.solexpress@gmail.com
              </Link>
              <Link to='tel:+84934379488' className='header-company-text' >
                <ion-icon name="call" />
                0934.379.488
              </Link>
            </div>
            <div className='header-detail-menu'>
              <Link to='/' className='header-detail-menu-link'>
                TRANG CHỦ
              </Link>
              <Dropdown menu={{ items }} placement="bottomLeft">
                <span className='header-detail-menu-link menu'>
                DỊCH VỤ
                </span>
              </Dropdown>
              <Link to='/tin-tuc' className='header-detail-menu-link'>
                TIN TỨC
              </Link>
              <Link to='/lien-he' className='header-detail-menu-link'>
                LIÊN HỆ
              </Link>
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </header>
  )
}

export default Header;