import React from "react";
import { Link } from 'react-router-dom';

const ContactMap = () => {
  return (
    <div className='service-detail-contact'>
      <Link to='tel:+84934379488'>
      <div className='service-detail-contact-call-box'>
        <span className='call-box-icon'>
          <ion-icon name="call"/>
        </span>
        <span className='call-text'>
          Gọi chúng tôi ngay để được tư vấn
        </span>
        <span className='call-number'>
          0934 379 488
        </span>
      </div>
      </Link>
      <Link to='https://maps.app.goo.gl/ChxFAzMB5ureaQh29' target="_blank" rel="noopener noreferrer" className="footer-text">
      <div className='service-detail-contact-map'>
        <span className='service-detail-contact-map-icon-view'>
          <ion-icon name="location" />
        </span>
        <span className='service-detail-contact-map-text'>Google Map</span>
      </div>
      </Link>
    </div>
  )
}

export default ContactMap;