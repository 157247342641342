import React from 'react';
import { Row } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TruckingBG from '@images/background/TruckingBG.png';
import AirFreightBG from '@images/background/AirFreightBG.png';
import SeaTransBG from '@images/background/SeaTransBG.png';
import DeliveryServiceBG from '@images/background/DeliveryServiceBG.png';
import TruckingIcon from '@images/icon/TruckingIcon.svg';
import AirFreightIcon from '@images/icon/AirFreightIcon.svg';
import SeaTransIcon from '@images/icon/SeaTransIcon.svg';
import DeliveryServiceIcon from '@images/icon/DeliveryServiceIcon.svg';
import { Link } from 'react-router-dom';

const Service = () => {

  const cards = [
    { title: 'Vận tải đường bộ', backgroundImage: TruckingBG, icon: TruckingIcon, url: '/dich-vu/van-tai-duong-bo' },
    { title: 'Vận tải đường hàng không', backgroundImage: AirFreightBG, icon: AirFreightIcon, url: '/dich-vu/van-tai-duong-hang-khong' },
    { title: 'Vận tải đường biển', backgroundImage: SeaTransBG, icon: SeaTransIcon, url: '/dich-vu/van-tai-duong-bien' },
    { title: 'Dịch vụ chuyển phát', backgroundImage: DeliveryServiceBG, icon: DeliveryServiceIcon, url: '/dich-vu/dich-vu-chuyen-phat' },
  ];

  return (
    <div className="service-info">
      <Row justify="center">
        <span className="service-info-text">DỊCH VỤ CỦA CHÚNG TÔI</span>
      </Row>
      <Row justify="center">
        <span className="service-info-international">Dịch vụ vận chuyển quốc tế</span>
      </Row>
      <Slider slidesToShow={4} slidesToScroll={1} speed={500} autoplay>
        {cards?.map((card, index) => (
          <Link to={card.url}>
            <div key={index} className="service-card">
              <div className="service-card-content" style={{ backgroundImage: `url(${card.backgroundImage})` }}>
                <div className="service-card-icon">
                  <img src={card.icon} alt={card.title} />
                </div>
                <div className="service-card-info">
                  <span className="service-card-info-header">LOGISTICS</span>
                  <span className="service-card-info-title">{card.title}</span>
                </div>
                <Link to={card.url} className='read-more'>
                  <span className='read-more-text'>Read More</span>
                  <span className='read-more-icon'>
                    <ion-icon name="chevron-forward-outline" />
                  </span>
                </Link>
              </div>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  )
}

export default Service;