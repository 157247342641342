import React from 'react';

const IntroducePage = () => {
  return (
    <React.Fragment>
      <div>IntroducePage</div>
    </React.Fragment>
  )
}

export default IntroducePage;