import React from 'react';
import { Tracking, Service, ShippingProcess, News } from './components';
import ImagePage01 from '@images/background/ImagePage01.png';
import ImagePage02 from '@images/background/ImagePage02.png';
import Logo from '@images/Logo.png';
import ContactIcon from '@images/icon/ContactIcon.gif';
import { Link } from 'react-router-dom';
import { Header } from '../../components';

const HomePage = () => {

  return (
    <React.Fragment>
      <Header>
        <div className="header-home-page">
          <div class="header-home-page-content">Cung cấp dịch vụ chuyển phát nhanh hàng hóa đi quốc tế hơn 200 quốc gia.</div>
          <div className="header-home-view-more">
            Xem chi tiết
            <ion-icon name="chevron-down" />
          </div>
        </div>
      </Header>
      <div className="home-page">
        <Tracking />
        <div className="vision-mission">
          <img src={ImagePage01} className="img-page-1" alt="img-page-1" />
          <img src={ImagePage02} className="img-page-2" alt="img-page-2" />
          <img src={Logo} className="img-logo" alt="img-logo" />
        </div>
        <Service />
        <div className="contact">
          <div className="contact-info">
            <span className="contact-info-title">
              LIÊN HỆ SOL EXPREESS NGAY
            </span>
            <span className="contact-info-content">
              Bạn đang tìm kiếm đối tác vận chuyển quốc tế tin cậy và chuyên nghiệp để đảm bảo hàng hoá đến tay an toàn và kịp thời?
            </span>
          </div>
          <div className="contact-play" />
          <img src={ContactIcon} alt="ContactIcon" className="contact-icon" />
          <Link to='tel:+84934379488' className="contact-call-now">
            Gọi Ngay
          </Link>
        </div>
        <ShippingProcess />
        <hidden News />
      </div>
    </React.Fragment>
  )
}

export default HomePage;