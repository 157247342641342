import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage, IntroducePage, ServiceDetailPage } from './pages';
import { Layout } from './components';
import "./assets/css/global.scss"

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/gioi-thieu" element={<IntroducePage />} />
          <Route path="/dich-vu/:name" element={<ServiceDetailPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
