import BackgroudPage01 from '@images/background/BackgroudPage01.png';
import React from 'react';
import '../ServiceDetailPage.scss';
import ContactMap from './ContactMap';

const SeaTransService = () => {
  return (
    <>
      <div className='sea-trans-service'>
        <p className='service-detail-content-title-text'>
          Quy trình xuất nhập khẩu hàng hóa bằng đường biển tại SOL Express
        </p>
        <div className='service-detail-content-flex-rows'>
          <div className='service-detail-content-flex-columns'>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p><b>Tiếp nhận yêu cầu:</b> Khách hàng cung cấp thông tin về loại hàng hóa, khối lượng, điểm lấy hàng và trả hàng.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p><b>Báo giá:</b> SOL Express báo giá vận chuyển hàng hóa cho khách hàng.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p><b>Ký hợp đồng:</b> Thỏa thuận và ký hợp đồng vận chuyển.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p><b>Khai báo hải quan:</b> Tiến hành khai báo và thông quan hàng hóa, chuẩn bị đầy đủ hồ sơ và giấy tờ cần thiết, kiểm tra hàng hóa theo quy định của hải quan.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p><b>Vận chuyển đến bến cảng:</b> Chuyển hàng hóa từ kho lưu trữ đến bến cảng để kiểm tra trước khi đưa lên tàu.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p><b>Xếp hàng lên tàu:</b> Thực hiện xếp hàng lên tàu và bắt đầu quá trình vận chuyển.</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p><b>Giao hàng:</b> Sau khi tàu cập cảng, hàng sẽ được dỡ xuống và giao đến người nhận.</p>
            </div>
          </div>
          <img src={BackgroudPage01} className='service-detail-content-images' alt='air-freight-advantage-image' />
        </div>
        <p className='service-detail-content-title-text'>
          Lợi ích khi lựa chọn dịch vụ vận tải đường biển tại SOL Express
        </p>
        <p className='service-detail-text'>
          SOL Express cung cấp <b>đa dạng dịch vụ vận chuyển đường biển</b>, bao gồm vận chuyển hàng lẻ (LCL) và hàng nguyên container (FCL). Chúng tôi thực hiện theo các hình thức từ nhận-giao hàng tận nơi, đáp ứng mọi nhu cầu vận chuyển của khách hàng.
        </p>
        <div className='service-detail-content-and-contact'>
          <div className='service-detail-content-view'>
            <p className='service-detail-text'>
              <b>Hệ thống mạng lưới lớn:</b> SOL Express sở hữu hệ thống mạng lưới đại lý ở nhiều quốc gia khác nhau, đảm bảo dịch vụ vận chuyển toàn cầu hiệu quả và đáng tin cậy.
            </p>
            <p className='service-detail-text'>
              <b>Phục vụ 24/7:</b> Đội ngũ nhân viên nhiệt tình, tận tâm, sẵn sàng phục vụ 24/7.
            </p>
            <p className='service-detail-text'>
              <b>Quy trình chuyên nghiệp:</b> SOL Express áp dụng quy trình chuyên nghiệp đảm bảo tính an toàn và bảo mật cho hàng hóa.
            </p>
            <p className='service-detail-text'>
              <b>Chi phí cạnh tranh:</b> Bảng giá cước phí cạnh tranh nhất trên thị trường.
            </p>
            <p className='service-detail-content-title-text'>
              Bảng giá dịch vụ vận tải đường biển tại SOL Express
            </p>
            <p className='service-detail-text'>
              Với quý khách hàng có nhu cầu gửi hàng thường xuyên, vui lòng liên hệ với SOL qua <b>hotline: 0934 379 488</b> hoặc <b>fanpage chính thức</b> của SOL Express. Quý Khách hàng có thể liên hệ trực tiếp với chúng tôi để lựa chọn hình thức gửi bảng báo giá chi phí vận chuyển hàng hóa đi các nước trên thế giới.
            </p>
          </div>
          <ContactMap />
        </div>
      </div>
    </>
  )
}

export default SeaTransService;