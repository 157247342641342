import BackgroudPage01 from '@images/background/BackgroudPage01.png';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../../components';
import { AirFreightService, ContactInfo, ServiceIntro, SeaTransService, TruckingService } from './components';
import { Link } from 'react-router-dom';
import './ServiceDetailPage.scss';

const lstService = [
  {
    keyIndex: 1,
    name: 'Vận tải đường bộ - đường sắt',
    urlEndPoint: '/dich-vu/van-tai-duong-bo',
  },
  {
    keyIndex: 2,
    name: 'Vận tải đường hàng không',
    urlEndPoint: '/dich-vu/van-tai-duong-hang-khong',
  },
  {
    keyIndex: 3,
    name: 'Vận tải đường biển',
    urlEndPoint: '/dich-vu/van-tai-duong-bien',
  },
  {
    keyIndex: 4,
    name: 'Dịch vụ chuyển phát',
    urlEndPoint: '/dich-vu/dich-vu-chuyen-phat',
  }
]

const ServiceDetailPage = () => {
  const { name } = useParams();
  const service = useMemo(() => lstService.find(x => x.urlEndPoint.includes(name)), [name]);

  return (
    <React.Fragment>
      <Header>
        <div className="header-service-detail">
          <div className='header-service-detail-title'>
            <span>{service?.name}</span>
          </div>
        </div>
      </Header>
      <div className='service-detail-page'>
        <div className='service-detail-intro'>
          <img src={BackgroudPage01} alt='service-detail-img' className='service-detail-intro-img' />
          <div className='service-detail-intro-content'>
            {
              lstService?.map(item => (
                <div className={`service-detail-intro-name${item === service ? ' active' : ''}`} key={item.id}>
                  <Link to={item.urlEndPoint} className={`service-detail-intro-name${item === service ? ' active' : ''}`}>
                    <span>{item.name}</span>
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </Link>
                </div>
              ))
            }
            <Link to='tel:+84934379488'>
              <div className='service-detail-intro-call-box'>
                <span className='call-box-icon'>
                  <ion-icon name="phone-portrait-outline" />
                </span>
                <span className='call-text'>
                  Gọi chúng tôi ngay để được tư vấn
                </span>
                <span className='call-number'>
                  0934 379 488
                </span>
              </div>
            </Link>
          </div>
        </div>
        <ServiceIntro keyIndex={service.keyIndex} />
        {
          service.keyIndex === 1 ? <TruckingService /> :
            service.keyIndex === 2 ? <AirFreightService /> :
              service.keyIndex === 3 ? <SeaTransService /> :
                <></>
        }
        <ContactInfo />
      </div>
    </React.Fragment>
  )
}

export default ServiceDetailPage;