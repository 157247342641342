import React from 'react';
import { Row, Col } from 'antd'
import '../HomePage.scss';
import NoteIcon from '@images/icon/NoteIcon.svg';
import CallCenterIcon from '@images/icon/CallCenterIcon.svg';
import CheckIcon from '@images/icon/CheckIcon.svg';
import FindIcon from '@images/icon/FindIcon.svg';
import GoodsIcon from '@images/icon/GoodsIcon.svg';
import TransferIcon from '@images/icon/TransferIcon.svg';
import MacroBG from '@images/background/MacroBG.png';
import Logo from '@images/Logo.png';
import DHLLogo from '@images/DHLLogo.svg';
import FedExLogo from '@images/FedExLogo.svg';
import UpsLogo from '@images/UpsLogo.svg';
import DoorIcon from '@images/icon/DoorIcon.svg';
import HCIcon from '@images/icon/HCIcon.svg';
import TravIcon from '@images/icon/TravIcon.svg';
import CallCenterOgrIcon from '@images/icon/CallCenterOgrIcon.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ShippingProcess = () => {
  return (
    <div className="shipping-process">
      <Row justify="center">
        <span className="shipping-process-info-text">QUY TRÌNH SOL EXPRESS</span>
      </Row>
      <Row justify="center">
        <span className="shipping-process-info-international">Quy trình vận chuyển</span>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8} className="shipping-process-info-item" >
          <img src={MacroBG} width={200} height={200} alt="icon-border" className="shipping-process-icon-border" />
          <div className="shipping-process-info-item-icon">
            <img src={NoteIcon} width={150} height={150} alt="icon" className="shipping-process-icon right" />
          </div>
          <div className="shipping-process-info-border" />
          <span className="shipping-process-info-item-text">Tiếp nhận thông tin khách hàng</span>
        </Col>
        <Col span={8} className="shipping-process-info-item" >
          <img src={MacroBG} width={200} height={200} alt="icon-border" className="shipping-process-icon-border" />
          <div className="shipping-process-info-item-icon">
            <img src={CallCenterIcon} width={150} height={150} alt="icon" className="shipping-process-icon" />
          </div>
          <div className="shipping-process-info-border" />
          <span className="shipping-process-info-item-text">Tư vấn dịch vụ</span>
        </Col>
        <Col span={8} className="shipping-process-info-item" >
          <img src={MacroBG} width={200} height={200} alt="icon-border" className="shipping-process-icon-border" />
          <div className="shipping-process-info-item-icon">
            <img src={GoodsIcon} width={150} height={150} alt="icon" className="shipping-process-icon" />
          </div>
          <div className="shipping-process-info-border" />
          <span className="shipping-process-info-item-text">Gửi hàng và đóng gói hàng hóa</span>
        </Col>
        <Col span={8} className="shipping-process-info-item" >
          <img src={MacroBG} width={200} height={200} alt="icon-border" className="shipping-process-icon-border" />
          <div className="shipping-process-info-item-icon">
            <img src={CheckIcon} width={150} height={150} alt="icon" className="shipping-process-icon right" />
          </div>
          <div className="shipping-process-info-border" />
          <span className="shipping-process-info-item-text">Kê khai hàng hóa hải quan</span>
        </Col>
        <Col span={8} className="shipping-process-info-item" >
          <img src={MacroBG} width={200} height={200} alt="icon-border" className="shipping-process-icon-border" />
          <div className="shipping-process-info-item-icon">
            <img src={FindIcon} width={150} height={150} alt="icon" className="shipping-process-icon" />
          </div>
          <div className="shipping-process-info-border" />
          <span className="shipping-process-info-item-text">Cung cấp mã tracking theo dõi đơn</span>
        </Col>
        <Col span={8} className="shipping-process-info-item" >
          <img src={MacroBG} width={200} height={200} alt="icon-border" className="shipping-process-icon-border" />
          <div className="shipping-process-info-item-icon">
            <img src={TransferIcon} width={150} height={150} alt="icon" className="shipping-process-icon" />
          </div>
          <div className="shipping-process-info-border" />
          <span className="shipping-process-info-item-text">Chuyển phát hàng tận nơi</span>
        </Col>
      </Row>
      <div className="content-background">
        <Slider slidesToShow={6} slidesToScroll={1} speed={500} autoplay className="list-company">
          <img src={FedExLogo} height={100} alt="icon-fedex" className="icon-fedex" />
          <img src={Logo} height={100} alt="icon-logo" className="icon-logo" />
          <img src={UpsLogo} height={100} alt="icon-ups" className="icon-ups" />
          <img src={DHLLogo} height={100} alt="icon-dhl" className="icon-dhl" />
          <img src={FedExLogo} height={100} alt="icon-fedex" className="icon-fedex" />
          <img src={Logo} height={100} alt="icon-logo" className="icon-logo" />
        </Slider>
        <Row gutter={[0, 24]} className="shipping-content">
          <Col span={12} className="shipping-process-info-item" >
            <div className="shipping-process-content-view">
              <div className="shipping-process-content-icon">
                <img src={TravIcon} alt="trav" width={30} height={30} />
              </div>
              <div className="shipping-process-content-info">
                <span className="shipping-process-content-header">Đáp ứng tiêu chuẩn quốc tế</span>
                <span className="shipping-process-content-body">Đảm bảo mọi tiêu chí khắt khe của quy trình vận chuyển hàng hóa quốc tế, tuân thủ quy định pháp lý, ứng dụng công nghệ tiên tiến để quản lý và theo dõi hàng hóa, đồng thời cung cấp các gói bảo hiểm linh hoạt để giảm rủi ro tài chính.</span>
              </div>
            </div>
          </Col>
          <Col span={12} className="shipping-process-info-item" >
            <div className="shipping-process-content-view">
              <div className="shipping-process-content-icon">
                <img src={DoorIcon} alt="trav" width={30} height={30} />
              </div>
              <div className="shipping-process-content-info">
                <span className="shipping-process-content-header">Giao nhận "door to door"</span>
                <span className="shipping-process-content-body">Phủ sóng khắp 63 tỉnh thành, mang đến giải pháp vận chuyển dễ dàng và thuận tiện nhất. Tuyến vận chuyển quốc tế của chúng tôi hoạt động theo nguyên tắc "door-to-door" - lấy hàng tận nơi, giao hàng tận cửa.</span>
              </div>
            </div>
          </Col>
          <Col span={12} className="shipping-process-info-item" >
            <div className="shipping-process-content-view">
              <div className="shipping-process-content-icon">
                <img src={HCIcon} alt="trav" width={30} height={30} />
              </div>
              <div className="shipping-process-content-info">
                <span className="shipping-process-content-header">Đảm bảo nguyên vẹn hàng hóa</span>
                <span className="shipping-process-content-body">Hàng hóa được vận chuyển an toàn và bảo mật, đảm bảo nguyên vẹn cho các loại hàng mẫu, hàng tiêu dùng, nông sản, hàng trễ chuyến, cũng như các chứng từ và giấy tờ quan trọng. </span>
              </div>
            </div>
          </Col>
          <Col span={12} className="shipping-process-info-item" >
            <div className="shipping-process-content-view">
              <div className="shipping-process-content-icon">
                <img src={CallCenterOgrIcon} alt="trav" width={30} height={30} />
              </div>
              <div className="shipping-process-content-info">
                <span className="shipping-process-content-header">Hỗ trợ 24/7</span>
                <span className="shipping-process-content-body">Hotline của chúng tôi hoạt động 24/7, luôn sẵn sàng hỗ trợ khách hàng giải đáp thắc mắc hoặc tra cứu đơn hàng. Dù có chênh lệch múi giờ đối với người nhận so với tại Việt Nam, SOL Express vẫn cam kết đáp ứng đầy đủ và kịp thời.</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>


    </div>
  )
}
export default ShippingProcess;