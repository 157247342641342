import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { DoubleRightOutlined } from '@ant-design/icons';
import './Footer.scss';
import Logo from '@images/Logo.png';
import TabFooterIcon from '@images/icon/TabFooterIcon.svg';
import PhoneFooterIcon from '@images/icon/PhoneFooterIcon.svg';
import TimerFooterIcon from '@images/icon/TimerFooterIcon.svg';

const services = [
  {
    name: 'VẬN TẢI ĐƯỜNG BỘ',
    link: '/dich-vu/van-tai-duong-bo',
  },
  {
    name: 'VẬN TẢI ĐƯỜNG BIỂN',
    link: '/dich-vu/van-tai-duong-bien',
  },
  {
    name: 'VẬN TẢI ĐƯỜNG HÀNG KHÔNG',
    link: '/dich-vu/van-tai-duong-hang-khong',
  },
  {
    name: 'DỊCH VỤ CHUYỂN PHÁT',
    link: '/dich-vu/dich-vu-chuyen-phat',
  },
];
const Footer = () => {

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = index => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <footer>
      <div className="footer-container">
        <Row className="footer-row">
          <Col span={8} className="footer-col">
            <div className='footer-icon'>
              <img src={TabFooterIcon} alt='TabFooterIcon' width={40} height={40} />
            </div>
            <div className="footer-content">
              <span className="footer-title">Trụ sở chính</span>
              <Link to='https://maps.app.goo.gl/ChxFAzMB5ureaQh29' target="_blank" rel="noopener noreferrer" className="footer-text">
                159/30 Hoàng Văn Thụ, P.8, Q.Phú Nhuận, Hồ Chí Minh
              </Link>
            </div>
          </Col>
          <Col span={8} className="footer-col">
            <div className='footer-icon'>
              <img src={PhoneFooterIcon} alt='PhoneFooterIcon' width={40} height={40} />
            </div>
            <div className="footer-content">
              <span className="footer-title">Hotline</span>
              <Link to='tel:+84934379488' className="footer-text">(+84) 934 379 488</Link>
            </div>
          </Col>
          <Col span={8} className="footer-col">
            <div className='footer-icon'>
              <img src={TimerFooterIcon} alt='TimerFooterIcon' width={40} height={40} />
            </div>
            <div className="footer-content">
              <span className="footer-title">Giờ làm việc</span>
              <span className="footer-text">T2 - T7: 8:30 - 17:00</span>
              <span className="footer-text">CN: Không hoạt động</span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer-bottom">
        <div className="footer-description">
          <img src={Logo} alt="Logo" className="logo" />
          <span className="company-name">Công ty TNHH Vận tải Quốc tế SOL</span>
          <span className="company-description">SOL EXPRESS chuyên cung cấp dịch vụ vận chuyển hàng hóa đi hơn 200 quốc gia uy tín - tận tâm - giá rẻ.</span>
        </div>
        <div className="footer-services">
          <div className="footer-services-title">
            <span>DỊCH VỤ</span>
          </div>
          {services.map((service, index) => (
            <Link
              to={service.link}
              key={index}
              className="footer-services-content"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <DoubleRightOutlined className={hoveredIndex === index ? 'hover' : ''} />
              <span>{service.name}</span>
            </Link>
          ))}
        </div>
        <div className="footer-services">
          <div className="footer-services-title">
            <span>FANPAGE</span>
          </div>
          <span>
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsolexpressvn&tabs=timeline&width=380&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" title="Facebook Website"
              width="380" height="130" scrolling="no" frameborder="0" allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
            </iframe>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
