import BackgroudPage01 from '@images/background/BackgroudPage01.png';
import React from 'react';
import '../ServiceDetailPage.scss';
import ContactMap from './ContactMap';

const TruckingService = () => {
  return (
    <>
      <div className='trucking-service'>
        <p className='service-detail-content-title-text'>
          Vận tải đường sắt tại SOL Express như thế nào?
        </p>
        <p className='service-detail-text'>
          SOL Express cung ứng dịch vụ <b>vận tải đường sắt</b> hàng gom theo lịch trình nhất định, vận chuyển nguyên toa xe, hàng không nguyên toa với các tuyến <b>Hồ Chí Minh - Hà Nội - Đà Nẵng.</b> Một trong những lợi ích lớn nhất của vận chuyển đường sắt là khả năng chở hàng hóa với quy mô lớn. Một đoàn tàu có thể chở hàng hóa với trọng lượng lên đến hàng ngàn tấn, giúp tiết kiệm chi phí và thời gian. Hơn nữa, đường sắt ít bị ảnh hưởng bởi các yếu tố ngoại vi như tình hình giao thông hay thời tiết, giúp đảm bảo việc vận chuyển diễn ra một cách suôn sẻ.
        </p>
        <div className='service-detail-content-flex-rows'>
          <div className='service-detail-content-flex-columns'>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p>Tính hiệu quả và tiết kiệm</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p>An toàn và đáng tin cậy</p>
            </div>
            <div className='service-detail-content-note'>
              <ion-icon name="checkmark-circle" />
              <p>Linh hoạt và tiện lợi</p>
            </div>
            <p className='service-detail-content-title-text'>
              Lợi ích khi lựa chọn dịch vụ vận tải đường bộ - đường sắt tại SOL Express:
            </p>
            <p className='service-detail-text'>
              <b>Tiết kiệm chi phí vận chuyển với bảng giá cước tối ưu và ưu đãi:</b> Chúng tôi không chỉ đảm bảo nhu cầu vận chuyển nhanh chóng mà còn giúp bạn tiết kiệm đáng kể chi phí, bên cạnh đó vẫn đảm bảo hàng hóa được vận chuyển an toàn và đúng thời gian.
            </p>
            <p className='service-detail-text'>
              <b>Vận chuyển đa dạng hàng hóa:</b> Chúng tôi nhận gửi và vận chuyển mọi loại hàng hóa từ tài liệu đến thực phẩm tươi khô, hàng gia dụng, hàng điện tử và nhiều loại khác, kể cả các mặt hàng khó như Mỹ phẩm, Thuốc tây,... với tỷ lệ thành công cao. Quy trình thủ tục và các giấy tờ gửi hàng của chúng tôi đơn giản, nhanh chóng, không rườm rà và phức tạp.
            </p>
          </div>
          <img src={BackgroudPage01} className='service-detail-content-images' alt='air-freight-advantage-image' />
        </div>
        <p className='service-detail-text'>
          <b>Thông báo rõ ràng:</b> Thông báo rõ ràng cho khách về lịch trình và tiến độ hàng hoá vận chuyển.
        </p>
        <div className='service-detail-content-and-contact'>
          <div className='service-detail-content-view'>
            <p className='service-detail-text'>
              <b>Phục vụ 24/7:</b> Đội ngũ nhân viên nhiệt tình, tận tâm, sẵn sàng phục vụ 24/7.
            </p>
            <p className='service-detail-content-title-text'>
              Bảng giá dịch vụ vận tải đường bộ - đường sắt tại SOL Express
            </p>
            <p className='service-detail-text'>
              Với quý khách hàng có nhu cầu gửi hàng thường xuyên, vui lòng liên hệ với SOL qua <b>hotline: 0934 379 488</b> hoặc <b>fanpage chính thức</b> của SOL Express. Quý Khách hàng có thể liên hệ trực tiếp với chúng tôi để lựa chọn hình thức gửi bảng báo giá chi phí vận chuyển hàng hóa đi các nước trên thế giới.
            </p>
          </div>
          <ContactMap />
        </div>
      </div>
    </>
  )
}

export default TruckingService;